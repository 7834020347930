/**!
 * Toggle-More-Block
 * 
 * @Author Felix Gutzmann (BRAIN interactive)
 * @Year 2019
 * @Version 0.1
 * 
 * will toggle element before the button
 * 
 * button muste be: 
 * 
 * <button data-toggle-more class="toggle-more-btn"></button>
 * 
 */

jQuery(document).ready(function(){

	var tmb = $('[data-toggle-more]');
	tmb.on('click', function(){
		$(this).prev().toggle();
		$(this).toggleClass('open');
	});

});