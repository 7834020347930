var clouds = 0;
var background = 0;
var foreground = 0;
var signs = 0;

function footerAnimation() {
    clouds = clouds - .1;
    background = background - .15;
    foreground = foreground - .2;
    signs = signs - .3;

    $(".footer-clouds").css({'background-position': clouds + '% 0%'})
    $(".footer-background").css({'background-position': background + '% 0%'})
    $(".footer-foreground").css({'background-position': foreground + '% 0%'})
    $(".footer-signs").css({'background-position': signs + '% 0%'})
}
footerAnimation();
setInterval(footerAnimation,100);