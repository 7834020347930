/**!
 * Read-More-Block
 * 
 * @Author Felix Gutzmann (BRAIN interactive)
 * @Author Martin Dueffer aka martin.dueffer@brain-interactive.com
 * @Year 2019/2020
 * @Version 0.3
 * 
 */

jQuery(document).ready(function(){

	/** Init Read-More-Block/s */
	var rmbs = jQuery('[data-read-more-block]');
	$.each(rmbs, function(e){
		var el = $(this);
		el.addClass('read-more-text-block read-more-parent-'+e);
		el.parent().append('<button id="read-more-block-toggle" class="read-more-block-toggle" data-read-more-parent="'+e+'"></button>');
		var rmb_height = (el.data('read-more-block-height')) ? el.data('read-more-block-height')+"px" : '600px';
		var css = {
			'height': rmb_height, 
			'overflow': 'hidden'
		};
		el.css(css).addClass('read-more-block-closed');
	});

	/** Read-More-Click-Event */
	var rmbbtn = jQuery('.read-more-block-toggle');
	rmbbtn.on('click', function(){
		jQuery(this).toggleClass('open');
		rmb = $('.read-more-parent-' + $(this).data('read-more-parent'));
		var rmb_height = (rmb.data('read-more-block-height')) ? rmb.data('read-more-block-height')+"px" : '600px';
		var css = {
			'height': rmb_height, 
			'overflow': 'hidden'
		};
		if(rmb.hasClass('read-more-block-closed')) {
			rmb.css({'height': '', 'overflow': ''});
			rmb.removeClass('read-more-block-closed');
		} else {
			rmb.css(css);
			rmb.addClass('read-more-block-closed');
			jQuery(window).scrollTop(rmb.offset().top);
		}
	});

});

/** 
 * content2 lightSlider + read more
 */

$(() => {
	$('div.slider-read-more-btn').on('click', function() {
		/* alert('bloedmann'); */
		$('body').append('<div id="overlay" onclick="removeElem();"><div class="overlay-content">' +
			$(this).parent().find('.c-wrap ul li.active').html() +
			'</div><div class="button"><p>X</p><br>SCHLIESSEN</div></div><script>function removeElem() { document.getElementById("overlay").remove();	}</script>');
	});
});

$(() => {

	var content2_slider = $('#content2-slider').lightSlider({
		item: 1,
		loop: false,
		slideMove: 1,
		controls: false,
		pager: false,
		slideMargin: 20,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		speed: 600,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					item: 2,
				}
			},
			{
				breakpoint: 640,
				settings: {
					item: 1,
				}
			}
		]
	});
	$('div.content-slider div.slider-wrapper .controls > button.prev').click(function () {
		content2_slider.goToPrevSlide();
	});
	$('div.content-slider div.slider-wrapper .controls > button.next').click(function () {
		content2_slider.goToNextSlide();
	});

	var inner_click = $('#content2-slider .inner');
	inner_click.on('click', function(){
		// copy content to overlay box
		$('body').append('<div id="overlay" onclick="removeElem();"><div class="overlay-content">' + 
							$(this).html() + 
							'</div><div class="button"><p>X</p><br>SCHLIESSEN</div></div><script>function removeElem() { document.getElementById("overlay").remove();	}</script>');

		//document.getElementById("#overlay").addEventListener("click", function (e) {
		//	$("#overlay").remove();
		//});
	});

	
	// not working
	var overlay_click = $('#overlay');
	overlay_click.on('click', function () {
		// todo
		//alert("FuckTheShit");
		$(this).removeClass('overlay');
	});

	$("#overlay").click(function () {
		$(this).remove();
	});

	//$( function () {
		
	//	$.fn.removeElem() {
	//		var elem = el;
	//		elem.remove();
	//	};
	//});

});