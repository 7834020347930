$(() => {

	$(window).on('scroll', function(){

		if($(window).scrollTop() > 300) {
			if(!$('div.slide-to-top-btn').hasClass('is-active')) {
				$('div.slide-to-top-btn').addClass('is-active fadeInRight');
				setTimeout(function(){
					$('div.slide-to-top-btn').removeClass('fadeInRight');
				}, 1000);
			}
		} else {
			if($('div.slide-to-top-btn').hasClass('is-active')) {
				$('div.slide-to-top-btn').addClass('fadeOutRight');
				setTimeout(function(){
					$('div.slide-to-top-btn').removeClass('fadeOutRight is-active');
				}, 1000);
			}
		}

	});

	$('div.slide-to-top-btn').click(function(){
		$('html').animate({ scrollTop: 0 }, 'slow'); return true; 
	});

});