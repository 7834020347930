/**
	 * 
	 * Mobile Menu / Mobile Info
	 * 
	 * This will build the mobile canvas menu 
	 * for the main menu and the info menu.
	 * 
	 * It appends the main menu before and
	 * the info menu after div.site-wrap.
	 * 
	 * If you look into the dev tools on chrome, 
	 * you will see what hapend when you move
	 * the screen size.
	 * 
	 */
$(() => {

	$(window).resize( function(e){
		addOrRemoveMobile();
	});

	var mode = false; // false=desktop, true=mobile
	var menu = $('#menu-main-menu');
	var info = $('.top-nav .kontakt');
	var site = $('.site-wrap');
	var btn_menu = $('.mobile-canvas-btn-menu');
	var btn_info = $('.mobile-canvas-btn-info');
	var btn_contact = $('.btn.contact-btn');
	
	addOrRemoveMobile(); // first init

	/** Add or remove elements */
	function addOrRemoveMobile() {
		if($(window).width() < 1200) { // mobile
			if(mode == false) {
				site.after('<div class="mobile-canvas mobile-menu-canvas"></div>');
				site.before('<div class="mobile-canvas mobile-info-canvas"></div>');
				site.addClass('mobile-canvas-wrap');

				/**
				 * Append Elements to the menus
				 */
				var logo = $('.__logo').clone();

				$('.mobile-menu-canvas').append('<div class="top-bar"></div>');
				$('.mobile-menu-canvas > .top-bar').append(logo.clone());
				$('.mobile-menu-canvas > .top-bar').append('<div class="close-btn"><i class="fas fa-times"></i></div>');
				$('.mobile-menu-canvas').append(menu.clone());
				$('.mobile-menu-canvas ul.sub-menu').removeClass('animated');
				$('.mobile-menu-canvas').append(btn_contact.clone());

				$('.mobile-info-canvas').append('<div class="top-bar"></div>');
				$('.mobile-info-canvas > .top-bar').append('<div class="close-btn"><i class="fas fa-times"></i></div>');
				$('.mobile-info-canvas > .top-bar').append(logo.clone());
				$('.mobile-info-canvas').append(info.clone());
							
				mode = true;
			}
		} else { // desktop
			if(mode == true) {
				$('.mobile-menu-canvas').remove();
				$('.mobile-info-canvas').remove();
				site.removeClass('mobile-canvas-wrap');
				mode = false;
			}
		}
	}

	/** Open Canvas menu */
	btn_menu.click(function(){
		$('.mobile-menu-canvas').toggleClass('open-menu');
		$('.mobile-info-canvas').removeClass('open-menu');
		site.removeClass('canvas-open-left').toggleClass('canvas-open-right');
	});
	
	/** Open Canvas info */
	btn_info.click(function(){
		$('.mobile-info-canvas').toggleClass('open-menu');
		$('.mobile-menu-canvas').removeClass('open-menu');
		site.removeClass('canvas-open-right').toggleClass('canvas-open-left');
	});

	/** Close Canvas */
	$('.close-btn').click(function(){
		$('.mobile-info-canvas').removeClass('open-menu');
		$('.mobile-menu-canvas').removeClass('open-menu');
		site.removeClass('canvas-open-left').removeClass('canvas-open-right');
	});

	/** toggle Submenu */
	$('.mobile-menu-canvas ul li.menu-item-has-children').click(function(){
		$(this).children('ul.sub-menu').toggle();
		$(this).toggleClass('sub-is-open');
	});

});
