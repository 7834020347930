/**!
 * Accordion Functions
 *
 * @Author Felix Gutzmann (BRAIN interactive)
 * @Author Martin Dueffer aka martin.dueffer@brain-interactive.com
 * @Year 2020
 * @Version 0.2
 *
 */

$(() => {

	/* faelle accordion */
	$('.faelle .accordion-title').click(function() {
		if($(this).parent().hasClass('open')) {
			$(this).parent().removeClass('open')
		} else {
			$('.faelle .accordion-wrapper').removeClass('open');
			$(this).parent().addClass('open');
		}
  	});

	/* answer-box accordion */
	$('.answer-box .accordion-title').click(function () {
		if ($(this).parent().hasClass('open')) {
			$(this).parent().removeClass('open')
		} else {
			$('.answer-box .accordion-wrapper').removeClass('open');
			$(this).parent().addClass('open');
		}
	});
    

});

/* old inline script theo
<script>

	var acc = document.getElementsByClassName("accordion");
	var i;

	for (i = 0; i < acc.length; {
		acc[i].addEventListener("click", function () {
			this.classList.toggle("active");

			var iconklapp = $(this).find('i');
			if (iconklapp.hasClass("fa-plus")) {
				iconklapp.removeClass("fa-plus");
				iconklapp.addClass("fa-minus")
			} else {
				iconklapp.removeClass("fa-minus");
				iconklapp.addClass("fa-plus")
			}


			var panel = this.nextElementSibling;
			if (panel.style.height == "0px") {
				panel.style.height = "auto";
				panel.style.borderTop = "solid 4px #17b481";
			} else {
				panel.style.height = "0px";
				panel.style.borderTop = "none";
			}
		});
		}
</script>
*/