window.$ = window.jQuery = require('jquery');

require('lightslider');
require('lightgallery');
require('lg-thumbnail');
require('lg-fullscreen');

require('./home.js');
require('./read-more.js');
require('./slide-to-top.js');
require('./toggle-more.js');
require('./accordion.js');
require('./footer.js');
require('./mobile-canvas.js');

/**
 * Social Media Sidebar
 */
$(function() {
  $("button.social-media-btn").click(function() {
    $(this).toggleClass("active");
    $("div.social-media-bar").children("ul").toggleClass("active");
  });
});


/**
 * Fixed Nav
 */
$(() => {

	$(window).scroll(function(){
		if ( screen.width <= 1200 ) {
			/* mobile scroll */
				/* $(".fixed-wrapper").addClass('is-fixed'); */
				$("nav").addClass('is-fixed');
				$("body").addClass('nav-is-fixed');
		} else {
			/* desktop scroll */
			console.log("SCROLL: allg " + $(window).scrollTop());
			if(($(window).scrollTop() > 40)) {
				$(".fixed-wrapper").addClass('is-fixed');
				$("nav").addClass('is-fixed');
				$("body").addClass('nav-is-fixed');
				/* $("top-nav").css("top", "-40px"); */
				console.log("SCROLL: " + $(window).scrollTop());
			/* } else if (($(window).scrollTop() < 40) && ) { */

			} else {
				$(".fixed-wrapper").removeClass('is-fixed');
				$("nav").removeClass('is-fixed');
				$("body").removeClass('nav-is-fixed');
				console.log("SCROLL: " + $(window).scrollTop());
			}
		}
	});

});


/**
 * Change Input Label to top
 */
$(() => {
	$('input, textarea').on('keyup', function(){
		if($(this).val()) {
			$(this).addClass('placeholder-top');
		} else {
			$(this).removeClass('placeholder-top');
		}
	})
});


/**
 * Input Checkbox Check
 */
$(() => {
	$('.wpcf7-list-item').click(function(){
		if($(this).children('input').is(':checked')) {
			$(this).children('input').attr('checked', false);
		} else {
			$(this).children('input').attr('checked', true);
		}
	});
});

/**
 * schlagwoerter tabs unterseiten
 */
$ (() => {

	$(".content .tab_content").hide();
	$(".content .tab_content:first-child").show();
/*	$(".menuetabs ul li:first-child").addClass("active"); */
	$(".menuetabs ul li").click(function(){


		$(".menuetabs ul li").removeClass("active");
		$(this).addClass("active");
		
		var current_tab_value = $(this).attr("data-list");
		$(".content .tab_content").hide();
		$("."+current_tab_value).show();

	});

	$(".menuetabs ul li").hover(function(){
		if(!$(this).hasClass("active")){
			$(".menuetabs ul li").removeClass("almostactive");
			$(this).addClass("almostactive");
		}
		
	},function(){
		$(this).removeClass("almostactive");
	});


});
