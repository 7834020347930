/** 
 * Autoheight header
 */
$(() => {
	function calculateHeaderHeight() {
		var bHeight = $('body').innerHeight();
		if($(window).width() > 1025) {
			var nHeight = $('nav').innerHeight();
		} else {
			var nHeight = 140;
		}
		var hHeight = bHeight - nHeight;
		$('header.home').css({'height': hHeight + 'px'});
	}
	if($(window).width() > 1500) {
		calculateHeaderHeight();
	}
	
	$(window).resize(function(){
		if($(window).width() > 1500) {
			calculateHeaderHeight();
		}
	});

});

/** 
 * lightSlider
 */
$(() => {

	var services_slider = $('#home-services').lightSlider({
		item:3,
		loop:false,
		slideMove:1,
		controls: false,
		pager: false,
		slideMargin: 20,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		speed:600,
		responsive : [
			{
				breakpoint:991,
				settings: {
						item:2,
					}
			},
			{
					breakpoint:640,
					settings: {
							item:1,
						}
			}
		]
	});  
	$('div.menu-rechtsgebiete div.slider-wrapper .controls > button.prev').click(function () {
		services_slider.goToPrevSlide();
	});
	$('div.menu-rechtsgebiete div.slider-wrapper .controls > button.next').click(function () {
		services_slider.goToNextSlide();
	});

});

/**
 * Footer mobile toggle
 */
$(() => {


	$('.toggle-btn').on('click', function(){
		if($(window).width() < 985) {
			$(this).toggleClass('active');
			$(this).next('.toggle').toggle();
		}
	});

});